.intro-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./../../images/coding3.jpg");
  background-size: cover;
}

.image {
  background: #000000d9;
  width: inherit;
  height: inherit;
}

.intro-content {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: $white-shade;
  font-family: 'Palanquin Dark', sans-serif;

  /* Extra small devices (phones, 600px and down) */
  @media screen and (max-width: 600px) { 
    h3 { 
      font-weight: bold;
      font-size: 40px;
    } 
    h2 {
      font-size: 22px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media screen and (min-width: 600px) { 
    h3 { 
      font-weight: bold;
      font-size: 50px;
  } 
    h2 {
      font-size: 22px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 768px) { 
    h3 { 
      font-weight: bold;
      font-size: 60px; 
  } 
    h2 {
      font-size: 32px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media screen and (min-width: 992px) { 
    h3 { 
      font-weight: bold;
      font-size: 70px;
  } 
    h2 {
      font-size: 32px;
    }
  }
}

