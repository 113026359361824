$icons-background-shade: #101010;
$icons-color: #b9b7b7;

@keyframes MoveUpDown {
  0%, 100% {
    top: 2px;
  }
  50% {
    top: 10px;
  }
}

.footer-container {
  height: 245px;

  /* Extra small devices (phones, 600px and down) */
  @media screen and (max-width: 600px) { height: 220px; }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media screen and (min-width: 600px) { height: 220px; }

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 768px) { height: 220px; }

  /* Medium devices (landscape tablets, 1024px and up) */
  @media screen and (min-width: 1024) { height: 245px; }

  /* Large devices (laptops/desktops, 992px and up) */
  @media screen and (min-width: 1280px) { height: 245px; }


  background-image: linear-gradient(90deg, #e02929 0%, #4d47f1 27%, #0493a7 64%, #32880d 83%, #e02929 100%);
}

.footer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000cf;
  height: inherit;
  border-top: 20px solid #00000040;

  div.footer-icons-container {
    display: flex;
    width: 19%;
    justify-content: space-between;

    /* Extra small devices (phones, 600px and down) */
    @media screen and (max-width: 600px) { width: 50%; }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media screen and (min-width: 600px) { width: 50%; }

    /* Medium devices (landscape tablets, 768px and up) */
    @media screen and (min-width: 768px) { width: 25%; }
    
    /* Medium devices (landscape tablets, 1024px and up) */
    @media screen and (min-width: 1024) { width: 21%; }

    /* Large devices (laptops/desktops, 992px and up) */
    @media screen and (min-width: 1280px) { width: 15%; }

    a {
      background-color: $icons-background-shade;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 3px;

      svg {
        color: $icons-color;
        font-size: 30px;
        transition: all .2s ease-in-out;

        &:hover {
          transform: scale(1.15);
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .scroll-up {
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: #05253c;
    height: 57px;
    width: 50px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;

    /* Extra small devices (phones, 600px and down) */
    @media screen and (max-width: 600px) { top: -48px; }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media screen and (min-width: 600px) { top: -48px; }

    /* Medium devices (landscape tablets, 768px and up) */
    @media screen and (min-width: 768px) { top: -48px; }

    /* Medium devices (landscape tablets, 1024px and up) */
    @media screen and (min-width: 1024) { top: -40px; }

    /* Large devices (laptops/desktops, 992px and up) */
    @media screen and (min-width: 1280px) { top: -40px; }

    svg {
      position: absolute;
      animation: MoveUpDown 1s linear infinite;
      color: #cecccc;
      font-size: 40px;
    }
  }

  p {
    color: $icons-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    margin-top: 25px;
    font-style: italic;
  }

  span {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 11.5px;
    cursor: pointer;

    a {
      color: $icons-color;
    }
  }
}