$title-shade: #c1c1c1;

.projects-container {
  padding: 60px 0px;
  min-height: 100vh;
  height: 100%;
  background-color: $background-shade-light;
  color: $title-shade;
}

.project-title-color {
  color: $title-shade;
}

.projects-list {
  max-height: 0px;
  overflow: hidden;
  margin: 25px auto;
  text-align: center;

  /* Extra small devices (phones, 600px and down) */
  @media screen and (max-width: 600px) { width: 80%; }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media screen and (min-width: 600px) { width: 80%; }

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 768px) { width: 70%; }
  
  /* Medium devices (landscape tablets, 1024px and up) */
  @media screen and (min-width: 1024) { width: 50%; }

  /* Large devices (laptops/desktops, 992px and up) */
  @media screen and (min-width: 992px) { width: 50%; }
}

.projects-list.is-visible {
  max-height: 4500px; // !
  transition: max-height 2s ease-in;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 30px;
    transition: 0.4s ease;
    margin-bottom: 0px;
  }

  span {
    font-size: 11.5px;
    height: fit-content;
  }

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 768px) { 
    flex-direction: row;
    align-items: normal;
    margin-bottom: 10px;

    span {
      margin: 13px 0px 0px 10px;
    }
  }
}

.project-details {
  // font-weight: 300;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  
  p:nth-child(1) {
    margin-bottom: 15px;
    font-size: 17.5px;
  }
  p:nth-child(n+2) {
    font-style: italic;
    text-align: center;
    margin-bottom: 5px;
    text-align: center;
    font-size: 15.6px;

    &::after {
      content:"\a";
      white-space: pre;
    }
  }
}

.tags {
  margin-bottom: 10px;

  .tag {
    text-transform: uppercase;
    font-size: 12.5px;
  }

  .tag:not(:first-child):before {
    content: "   •   ";
    white-space: pre;
    color: #882d2d;

    /* Extra small devices (phones, 600px and down) */
    @media screen and (max-width: 600px) { content: " • " }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media screen and (min-width: 600px) { content: " • " }

    /* Medium devices (landscape tablets, 768px and up) */
    @media screen and (min-width: 768px) { content: "  •  " }
    
    /* Medium devices (landscape tablets, 1024px and up) */
    @media screen and (min-width: 1024) { content: "   •   " }

    /* Large devices (laptops/desktops, 992px and up) */
    @media screen and (min-width: 992px) { content: "   •   " }
  }
}
