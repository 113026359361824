$title-shade: #dadada;
$border-shade: #4d4e4e63;
$hover-shade: #075d9a;

.about-container {
  cursor: pointer;
  padding: 60px 0px 60px 0px;
  cursor: initial;
  background-color: $background-shade-light;
}

.about-title-color {
  color: $title-shade;
}

.image-container {
  clip-path: polygon(20% 0%, 77% 0, 100% 20%, 100% 57%, 80% 82%, 19% 82%, 0 59%, 0% 20%);
  width: 280px;
  height: 364px;
  background: #9a072942;
  display: flex;
  justify-content: center;

  img {
    width: 270px;
    clip-path: polygon(20% 0%, 77% 0, 100% 20%, 100% 57%, 80% 82%, 19% 82%, 0 59%, 0% 20%);
  }
}

.about-introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  p {
    text-align: center;
    font-size: 18px; // !
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 0px;
    
    /* Medium devices (landscape tablets, 768px and up) */
    // @media screen and (min-width: 768px) { font-weight: 200; }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 1024px) { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
}

.introduction-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 768px) { width: 65%; }

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 1024px) { 
    width: 40%;
    margin: 0 60px;
  }

  p.about-me {
    margin-top: -30px;

    /* Medium devices (landscape tablets, 768px and up) */
    @media screen and (min-width: 768px) { margin-top: -40px; }

    /* Medium devices (landscape tablets, 768px and up) */
    @media screen and (min-width: 1024px) { margin-top: -80px; }
  }
}

.education {
  font-family: 'Montserrat', sans-serif;

  div > h3 {
    font-weight: 300;
  }

  a {
    font-weight: 600;
    text-decoration: none;
    color: #d2d2d2;

    &:hover {
      color: $title-shade;
      text-decoration: underline;
    }
  }
}

.secondary-divider-container {
  display: flex;
  justify-content: center;

  div {
    width: 900px;
    margin: 15px 0px 15px 0px;
  }
}

.icons-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  a {
    color: $title-shade;
    margin-right: 20px;
    transition: 0.3s ease; 

    &:hover {
      cursor: pointer;
      color: $hover-shade;
    }
  }
}
