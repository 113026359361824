html {
  scroll-behavior: smooth;
}

$background-shade-light: #0e0e0ef5;
$background-shade-dark: #080808fc ;
$white-shade: #cecece;
$blue-shade: #153669;
$red-shade: #7d0808c4;
$golden-shade: #cc9d0a;
$grey-shade: #bdbdbd26;

// Components
@import "components/floatingButton";
@import "components/navbar";
@import "components/footer";

// Sections
@import "sections/intro";
@import "sections/about";
@import "sections/skills";
@import "sections/projects";
@import "sections/contact";

// Shared styles

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 300;

  /* Extra small devices (phones, 600px and down) */
  @media screen and (max-width: 600px) { font-size: 30px; }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media screen and (min-width: 600px) { font-size: 40px; }
}

.divider {
  width: 290px;
  height: 3px;
  margin: 20px 0px;
  background: #ccc;
  float: none;
  background-color: $blue-shade;
}

.dot-divider {
  color: $white-shade;
  margin: 15px 0px;
  font-size: 17px;
  &::before {
    content: "⦿";
  }
}

.secondary-divider {
  width: 120px;
  height: 3px;
  margin: 25px 0px 45px 0px;
  background: #2b2b2bbf;
  float: none;
}