$title-shade: #c5c5c5;

@mixin hover-color($color) {
  &:hover {
    background: $color !important;
  }
}

.skills-container {
  background-color: $background-shade-dark;
  padding: 60px 0px;
  color: $title-shade;
}

.skills-title-color {
  color: $title-shade;
}

.skills-list {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;

  h4 {
    font-weight: 300;
    font-size: 25px;

    /* Medium devices (landscape tablets, 768px and up) */
    // @media screen and (min-width: 768px) { font-weight: 200; }
  }
}

.skills-introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  p {
    text-align: center;
    font-size: 18px; // !
    font-family: 'Montserrat', sans-serif;
    width: 80%;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 0px;

  //  @media screen and (min-width: 768px) { 
  //     width: 65%;
  //     font-weight: 200;
  //   }
  }
}

.skills-data {
  margin-top: 25px;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 10px;

  /* Extra small devices (phones, 600px and down) */
  @media screen and (max-width: 600px) { grid-template-columns: repeat(3, max-content); }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media screen and (min-width: 600px) { grid-template-columns: repeat(3, max-content); }

  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 768px) { grid-template-columns: repeat(3, max-content) }
  
  /* Medium devices (landscape tablets, 1024px and up) */
  @media screen and (min-width: 1024) { grid-template-columns: repeat(6, max-content) }

  /* Large devices (laptops/desktops, 992px and up) */
  @media screen and (min-width: 992px) { grid-template-columns: repeat(6, max-content) }

  span {
    border: 1px solid $title-shade;
    border-radius: 15px;
    font-size: 13px;
    padding: 5px 10px;
    background: transparent;
    transition: all 0.2s ease;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    width: fit-content;

    /* Medium devices (landscape tablets, 1024px and up) */
    @media screen and (min-width: 1024) { margin-right: 25px; }

    &:hover {
      cursor: pointer;
      // background: red;
      border-color: #fff;
      color: #fff;
    }
  }
}

.red {
  @include hover-color(#ff00009e);
}
.blue {
  @include hover-color(#0000ff94);
}
.turquoise {
  @include hover-color(#00dce6ad);
}
.green {
  @include hover-color(#0dc71b9c);
}
.yellow {
  @include hover-color(#ffeb3bad);
}
.pink {
  @include hover-color(#ea2548a6);
}

.other-skills-container {
  margin-top: 35px;
  display: flex;
  width: 80%;
  justify-content: space-around;

  h4 {
    font-size: 19px;
    font-style: italic;
  }

  li {
    font-size: 14px;
    margin-left: -35px;
  }
}
